export default function (base64) {
  const binaryStringData = base64.substring(base64.indexOf(',') + 1, base64.length)
  const binaryString = window.atob(binaryStringData)
  const binaryLen = binaryString.length
  const fileContent = new Uint8Array(binaryLen)
  for (let i = 0; i < binaryLen; i++) {
    const ascii = binaryString.charCodeAt(i)
    fileContent[i] = ascii
  }
  const blob = new Blob([fileContent], { type: 'application/octet-stream' })
  return window.URL.createObjectURL(blob)
}
