<template>
  <div class="c-ar-entry">
    <div class="c-ar-entry__icon">
      <span class="xp xp--huge xp-augmented_reality"></span>
    </div>
    <div class="c-ar-entry__loading" v-if="$xp.device.isLoan">
      <i class="xp xp-spinner xp--large is-spinning"></i>
    </div>
    <div class="c-ar-entry__non-loan-warning" v-else>
      <p>
        {{ $t("global.arNotAvailable") }}
      </p>
    </div>
  </div>
</template>

<script>
import entryMixin from '../../mixins/entry'
import debug from 'debug'

const log = debug('xpedeo-core:ArEntry')
debug.enable(process.env.VUE_APP_DEBUG)

export default {
  name: 'ArEntry',
  mixins: [entryMixin],
  mounted () {
    console.log(this)
    if (this.$xp.device.isLoan) {
      log('in mounted in ArEntry, in Loan Device')
      const lastPage = this.$xp.history.historyArray[this.$xp.history.historyArray.length - 2]
      this.$xp.history.saveHistoryState()
      this.$xp.settings.saveSettingsState()
      window.localStorage.setItem('shouldRestoreHistory', true)
      window.XpedeoAR.onBackButton((autonextpage) => {
        this.$xp.history.restoreHistoryState()
        this.$xp.settings.restoreSettingsState()
        window.localStorage.setItem('shouldRestoreHistory', false)
        log('autonextpage value ', autonextpage)
        if (autonextpage) {
          this.goToNextPage()
        } else { this.$xp.history.back() }
        log('onBackButton js callback, restoring History')
      }, function (error) {
        log('onbackButton callback error!', error)
      })
      const arUrl = this.$xp.settings.baseUrl + 'xpedeo/' + this.entryData.AR.Url
      this.$xp.device.getRemappedUrl(arUrl).then((remappedUrl) => {
        window.XpedeoAR.startSceneformWithUrl([lastPage.path, remappedUrl])
      })
    } else {
      log('in ArEntry, not a Loan Device')
    }
  },
  methods: {
    goToNextPage () {
      if (this.pageData.NextPageID) {
        this.$router.push('/page/' + this.pageData.NextPageID)
      }
    }
  }
}
</script>
